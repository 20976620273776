let Share = {
   $socials: $('#socialsShares'),
   dynamicUrl: '',
   facebook: function (purl, ptitle, pimg, text) {
      let url = 'http://www.facebook.com/sharer.php?s=100';
      url += '&p[title]=' + encodeURIComponent(ptitle);
      url += '&p[summary]=' + encodeURIComponent(text);
      url += '&p[url]=' + encodeURIComponent(purl);
      url += '&p[images][0]=' + encodeURIComponent(pimg);
      Share.popup(url);
   },
   twitter: function (purl, ptitle) {
      let url = 'http://twitter.com/share?';
      url += 'text=' + encodeURIComponent(ptitle);
      url += '&url=' + encodeURIComponent(purl);
      url += '&counturl=' + encodeURIComponent(purl);
      Share.popup(url);
   },

   me: function (el) {
      let link = el.href + window.location.href;
      // console.log("el ",el)
      if (!window.historyChanged) {
         console.log('Share.dynamicUrl',Share.dynamicUrl)
         link = (Share.dynamicUrl !== '') ? Share.dynamicUrl : el.href + $(el).data('url');
      }

      Share.popup(link);
      return false;
   },

   popup: function (url) {
      window.open(url, '', 'toolbar=0,status=0,width=626,height=436');
   },

   getSharesCount: function (url) {
      url = url || location.href;

      let socials = {
         'facebook': 'https://graph.facebook.com/?id=' + url,
         'twitter': 'https://cdn.api.twitter.com/1/urls/count.json?url=' + url
      };

      $.each(socials, function (key, val) {
         $.get(val, function (data) {
            var socialCount = 0;
            switch (key) {
               case 'facebook':
                  if (data.share) {
                     socialCount = parseInt(data.share.share_count);
                  }
                  break;
               case 'twitter':
                  socialCount = parseInt(data.count);
                  break;
            }

            socialCount = (isNaN(socialCount)) ? 0 : socialCount;

            Share.$socials.find('.' + key).find('.counter').html(socialCount);
         }, 'jsonp');
      });

      //GooglePlus
      $.ajax({
         type: 'POST',
         url: 'https://clients6.google.com/rpc',
         processData: true,
         contentType: 'application/json',
         data: JSON.stringify({
            'method': 'pos.plusones.get',
            'id': url,
            'params': {
               'nolog': true,
               'id': url,
               'source': 'widget',
               'userId': '@viewer',
               'groupId': '@self'
            },
            'jsonrpc': '2.0',
            'key': 'p',
            'apiVersion': 'v1'
         }),
         success: function (response) {
            Share.$socials.find('.google').find('.counter').html(response.result.metadata.globalCounts.count);
         }
      });

      Share.$socials.find('.twitter').find('.counter').html('');
   }
};

if ($('#socialsShares').length)
   Share.getSharesCount('https://bukovel.com');

$.extend({
   'Share': Share
});

export default Share;