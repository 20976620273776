// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import 'owl-carousel';
import './sly-carousel.js';

import Main from './pages/Main';
import News from './pages/News';
import Settings from './Settings';
import VacancyPage from './pages/Vacancy';
import OurObjects from './pages/OurObjects';
import OurObjectsDeteil from './pages/OurObjectDetail.js';
import OurCompany from './pages/OurCompany.js';
import PhotoReport from './pages/PhotoReport.js';
import MapOfRoad from './pages/MapOfRoad';
import Global from './Global';
import Share from './Share';

$(document).ready(function () {
   Global.init();
   Main.init();
   News.init();
   OurObjects.init();
   OurObjectsDeteil.init();
   OurCompany.init();
   VacancyPage.init();
   PhotoReport.init();
   MapOfRoad.init();

   window.Share = Share;



   /**
    * add svg view port for responsive svg
    * */
    $('.open-card__map').find('svg').attr({'viewBox': '0 0 350 350'});


    $(function () {
        $(".preloader").fadeOut();
    });

});