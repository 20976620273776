import Settings from './Settings';

const Global = {
    init: function () {
        this.checkHeaderScroll();
        this.scrollMenu();
        this.photoCarusel();
        this.hamburger();
        this.activeLeng();
        this.activeItemMenu();
        this.activeBreadcrumbs();
        // this.breadCrumbs();
        this.limitTo();
    },

    /**
     *  limit to (string limit)
     * */
    limitTo: function () {
        const limitString = $("*[class^='string-limit'], *[class*=' string-limit']"),
            limitToWithoutDots = $("*[class^='withoutDotsString-limit'], *[class*=' withoutDotsString-limit']");

        function LimitTo(cls, dots){
            if(cls.length){
                $.each(cls, function (index, value) {
                    var $value = $(value),
                        sliceChar = +$value.attr('class').split(" ")[1].split("-")[2],
                        valueCutSpace = $value.text().replace(/  +/g, ' ');
                    if(dots){
                        if (valueCutSpace.length > sliceChar) {

                            $value.text(valueCutSpace.substring(0, sliceChar) + "...");
                        }
                    } else {
                        if (valueCutSpace.length > sliceChar) {
                            $value.text(valueCutSpace.substring(0, sliceChar));
                        }
                    }

                })
            }
        }
        LimitTo(limitString, true);
        LimitTo(limitToWithoutDots, false);

    },

    /**
     *  This header zoom efect
     */
    checkHeaderScroll: function () {
        if (Settings.$headerImage.length) {
            const range = 225,
                height = Settings.$headerImage.outerHeight() + 25,
                offset = height / 2;

            $(window).scroll(function () {
                const scroll = $(window).scrollTop(),
                    opacity = 1 - (scroll - offset + range) / range;

                if (scroll < range) {
                    Settings.$headerImage.css({
                        transform: 'scale(' + (100 + scroll / 5) / 100 + ')',
                        'opacity': opacity
                    });
                }
            });
        }
    },

    /**
     *  scroll menu
     **/
    scrollMenu: function () {
        // TODO. reformat! here don't need so many classes

        let $headerlogo = $('.header-logo'),
            $headerwrap = $('.header-wrap'),
            $mobilMenuWrap = $('.mobile-menu-wrap'),
            $headerWrapMenu = $('.header-wrap-menu')

        function checkScroll(elementScroll) {
            if (elementScroll > 0) {
                Settings.$headerWrap.addClass('default');
                Settings.$mobileMenuWrap.addClass('default');
                $headerlogo.addClass('header-logo-small');
                $headerwrap.addClass('header-wrap-small');
                $mobilMenuWrap.addClass('mobile-menu-wrap-small');
                $headerWrapMenu.addClass('header-wrap-menu-effect');
            } else {
                Settings.$headerWrap.removeClass('default');
                Settings.$mobileMenuWrap.removeClass('default');
                $headerlogo.removeClass('header-logo-small');
                $headerwrap.removeClass('header-wrap-small');
                $mobilMenuWrap.removeClass('mobile-menu-wrap-small');
                $headerWrapMenu.removeClass('header-wrap-menu-effect');
            }
        }

        checkScroll(window.scrollY);

        $(window).scroll(function () {
            checkScroll($(this).scrollTop());
        });
    },

    /**
     *  global photo gallery
     **/
    photoCarusel: function () {
        let $carouselPhoto = $(".photo-carousel-wrap");

        if ($carouselPhoto.length) {
            $carouselPhoto.owlCarousel({
                loop: true,
                dots: false,
                nav: true,
                margin: -1,
                navText: [],//["<img src='images/icons/owl-prev.png'>", "<img src='images/icons/owl-next.png'>"],
                navClass: ['owl-prev', 'owl-next'],
                responsive: {
                    0: {
                        items: 1
                    },
                    415: {
                        items: 2
                    },
                    700: {
                        items: 3
                    },
                    1000: {
                        items: 4
                    },
                    1200: {
                        items: 5
                    },
                    1400: {
                        items: 5
                    },
                    1921: {
                        items: 6
                    }
                }
            });
        }
    },


    /**
     * hamburger for mobile
     * */
    hamburger: function () {
        var $body = $('body'),
            $hamburger = $('.hamburger');

        $hamburger.on('click', function () {
            $body.toggleClass('body-scroll');
        });

        /* Mobile menu */
        Settings.$headerHamburger.on('click', function () {
            Settings.$headerWrap.toggle();
        });

        // line of hamburger
        var forEach = function (t, o, r) {
            if ("[object Object]" === Object.prototype.toString.call(t))
                for (var c in t) Object.prototype.hasOwnProperty.call(t, c) && o.call(r, t[c], c, t);
            else
                for (var e = 0, l = t.length; l > e; e++) o.call(r, t[e], e, t)
        };

        var hamburgers = document.querySelectorAll(".hamburger");
        if (hamburgers.length > 0) {
            forEach(hamburgers, function (hamburger) {
                hamburger.addEventListener("click", function () {
                    this.classList.toggle("is-active");
                }, false);
            });
        }

    },

    /**
     * active lang
     * */
    activeLeng: function () {
        $('.lang').click(function () {
            $('.lang').removeClass("active");
            $(this).addClass("active");
        });
    },

    /**
     * active Item Menu
     * */
    activeItemMenu: function () {

        // todo write active class for menu

        $('ul.menu').children().click(function () {
            $('ul.menu').children().removeClass("active");
            $(this).addClass("active");
        });
    },

    /**
     * active breadcrumbs in our object list
     * */
    activeBreadcrumbs: function () {

        const path = window.location.pathname,
            cardSwich = $('.card-swith').find($('.switch-item'));
        cardSwich.removeClass('active');
        if (path === '/listobject.html') {
            $('.card-list__list').addClass('active');
        } else if (path === '/show-on-map.html') {
            $('.card-list__map').addClass('active');
        }
    },

}


// google.maps.event.addListener(marker, 'click', function (evt) {
//     infowindow.open(gmap, marker);
//
//     setTimeout(function() {
//         gmap.setCenter(gmap.setCenter(evt.latLng));
//         console.log("gmap.setCenter(marker.getPosition());", gmap.setCenter(evt.latLng))
//         // gmap.panBy(150, 0); for desktop
//         gmap.panBy(-25, -430); // for phone
//         // setTimeout(moveMap, 10);
//     },300)
//
// });




export default Global;