import ProgressBar from 'progressbar.js';
import Clipboard from 'clipboard';

const OurObjectDetail = {
   init: function () {
      this.objectCarusel();
      this.progressBarDetail(); //progress bar for big card
      this.popUp();              // mobile pop up share
      this.scrollToCard(); // handle click coll scroll to open card
      this.breadCrumbs();
      this.shareClipboard();
      this.activeButtons();
   },
    activeButtons: function (){
      let $MainBlock = $('.main-list-object'),
         $prevButton = $MainBlock.find('.owl-prev-page'),
         $nextButton = $MainBlock.find('.owl-next-page');

        $prevButton.addClass('active-button');


        $prevButton.on('click', function (){
           $prevButton.addClass('active-button');
            $nextButton.removeClass('active-button')
        });
        $nextButton.on('click',function (){
            $prevButton.removeClass('active-button');
            $nextButton.addClass('active-button');
        })


    },

   objectCarusel: function () {
      var self = this;

      $('.caruser').owlCarousel({
         loop: true,
         dots: false,
         nav: true,
         margin: -1,
         navClass: ['owl-prev-page', 'owl-next-page'],
         navText: true,
         URLhashListener: false,
         autoplayHoverPause: false,
         startPosition: 'URLHash',
         responsive: {   // Адаптивность. Кол-во выводимых элементов при определенной ширине.
            0: {
               items: 1
            },
            1400: {
               items: 1
            }
         }
      }).on('translated.owl.carousel', function (event) {
         const $dataText = $(this).find('.active').find('.js-open-card__city')[0].textContent,
           $setText = $('.js-crumbs-text');
         self.setBreadcrumbs($setText, $dataText);
      });
   },

   breadCrumbs: function () {
      const self = this;
      $('.grid-item').on('click', function () {
         const $dataText = $(this).find('.location-card__city')[0].textContent,
           $setText = $('.js-crumbs-text');
         self.setBreadcrumbs($setText, $dataText);
      });
   },

   // get data and set data to element
   setBreadcrumbs: function (set, get) {
      set.text(get);
   },

   progressBarDetail: function () {
      $.each($('.js-open-card__progress'), function (index, value) {

         // get procent for circle
         const procent = +$(this).attr('value');

         const bar = new ProgressBar.Circle(value, {
            color: '#FFEA82',
            trailColor: '#979797',
            trailWidth: 1,
            duration: 1400,
            easing: 'easeInOut',
            strokeWidth: 6,
            text: {
               autoStyleContainer: false
            },
            step: function (state, circle) {

               let value = Math.round(circle.value() * 100);

               if (value === 0) {
                  circle.setText('');
               }
               else {
                  circle.setText(value + '%');
               }

               // added color for every category of percentages
               if (value < 25) {
                  circle.path.setAttribute('stroke', '#f8061d');
               }
               else if (value > 25 && value < 50) {
                  circle.path.setAttribute('stroke', '#ff6800');
               }
               else {
                  circle.path.setAttribute('stroke', '#50e3c2');
               }
            }
         });

         /**
          * here should be something like this
          * here I put perenteges and  / it to 100
          * */

         bar.animate(procent / 100);
          bar.text.style.color = '#010E40';
          bar.text.style.fontSize = '18px';
          bar.text.style.fontWeight = 'bold';

      });
   },

   popUp: function () {
      var self = this;

      $('.open-card__phone-share').click(function (event) {
         event.preventDefault();
         $('.overlay-object').fadeIn(400,
           function () {
              $('.modal-object')
                .css('display', 'block')
                .animate({opacity: 1, top: '50%'}, 200);
           });

         /**
          *
          * - Get data from big opent-card and fill it to pop-up small card
          * - In case procent I chose a svg element
          * - In case procent I chose gradient
          *
          * */
           // get data and put it in pop-up // I didn't find another way
         const $openCard = $(this).closest($('.open-card')),
           $smallCard = $('.modal-object'),
           $cardObject = $('.card__object');

         const dataFromBigCard = {
            $icon: $openCard.find('.open-card__icon').html(),
            $locationCity: $openCard.find('.js-open-card__city').text(),
            $typeOfRepair: $openCard.find('.js-open-card__type').text(),
            $urlImg: $openCard.attr('value'),
            $date:   $openCard.find('.ready-to').text(),
            $progress: $openCard.find('.open-card__controller').attr('value'),
            $iconRepair: $openCard.find('.js-open-card__type').attr('data-type-repair'),
         };

          // if admin doesn't load img for card background put cover
          if (dataFromBigCard.$urlImg === 'null' || dataFromBigCard.$urlImg == '/media/' ) {
              if (+dataFromBigCard.$progress === 0) {
                  dataFromBigCard.$urlImg = '/static/images/our-objects/road-done/0.jpg'
              } else if(+dataFromBigCard.$progress < 25 && +dataFromBigCard.$progress !== 0){
                  dataFromBigCard.$urlImg = '/static/images/our-objects/road-done/25.jpg'
              } else if(+dataFromBigCard.$progress > 25 && +dataFromBigCard.$progress < 50) {
                  dataFromBigCard.$urlImg = '/static/images/our-objects/road-done/50.jpg'
              } else if(+dataFromBigCard.$progress >= 50 && +dataFromBigCard.$progress < 100){
                  dataFromBigCard.$urlImg = '/static/images/our-objects/road-done/50-100.jpg'
              } else if (+dataFromBigCard.$progress === 100) {
                  dataFromBigCard.$urlImg = '/static/images/our-objects/road-done/100.jpg'
              }
          }

         // get all elements
         var dataToSmallCard = {
            $icon: $smallCard.find('.location-card__road-number'),
            $locationCity: $smallCard.find('.location-card__city'),
            $typeOfRepair: $smallCard.find('.repair__value'),
            $date: $smallCard.find('.ready-highway__info'),
            $urlImg: $smallCard.find('.card__img'),
            $progress: $smallCard.find('#svg-circle-pop-up'),
             $iconRepair: $smallCard.find('.repair__icon').find('svg')
         };

         // set each element data
         dataToSmallCard.$icon.html(dataFromBigCard.$icon);
         dataToSmallCard.$locationCity.text(dataFromBigCard.$locationCity);
         dataToSmallCard.$typeOfRepair.text(dataFromBigCard.$typeOfRepair);
         dataToSmallCard.$urlImg.css('background-image', 'url(' + dataFromBigCard.$urlImg + ')');
         dataToSmallCard.$progress.attr('value', dataFromBigCard.$progress);
         dataToSmallCard.$date.text(dataFromBigCard.$date);
         dataToSmallCard.$iconRepair.html(`<svg class="done-icon" version="1.1"><use xlink:href="${dataFromBigCard.$iconRepair}"></use></svg>`)


         var cardID = $openCard.data('id');
         /* share id add to link */
            $smallCard.find('.reference-item-facebook').attr('data-url',`http://pbs.bukovel.ua/road-${ cardID }`);
            $smallCard.find('.reference-item-messanger').attr('href',`fb-messenger://share/?link=http://pbs.bukovel.ua/road-${ cardID }`);
            $smallCard.find('.reference-item-whatsapp').attr('href',`whatsapp://send?text=http://pbs.bukovel.ua/road-${ cardID }`);
            $smallCard.find('.reference-item-googleplus').attr('data-url',`http://pbs.bukovel.ua/road-${ cardID }`);
            $smallCard.find('.reference-item-twitter').attr('data-url',`http://pbs.bukovel.ua/road-${ cardID }`);




         // kill svg from container. Its insted of destroy progressbar
         dataToSmallCard.$progress.empty();

         if (dataFromBigCard.$progress == '0') {
            // added circle dpne for circle
             dataToSmallCard.$progress.append('<svg width="49" height="49" id="Шар_1" data-name="Шар 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.6 47.6"><defs><style>.cls-1a{fill:#ff6800;}.cls-2a{fill:#fdad79;}</style></defs><title>icons for hotelprogr2</title><polygon class="cls-1a" points="23.8 25.5 26.8 28.8 26.8 30.6 20.9 30.6 20.9 28.8 23.8 25.5"/><polygon class="cls-1a" points="23.8 20.2 21.5 17.7 26.1 17.7 23.8 20.2"/><path class="cls-2a" d="M23.8,47.6A23.8,23.8,0,1,1,47.6,23.8,23.83,23.83,0,0,1,23.8,47.6ZM23.8,2A21.8,21.8,0,1,0,45.6,23.8,21.83,21.83,0,0,0,23.8,2Z"/><path class="cls-2a" d="M30.5,34.6H17.1V27l3.51-4.25L17.1,18.45V11H30.5v7.45l-3.42,4.34,3.42,4.26Zm-11.4-2h9.4V27.75l-4-4.94,4-5.06V13H19.1v4.75l4.09,5.06-4.09,5Z"/><path class="cls-1a" d="M31.8,34.6H15.9a1,1,0,1,1,0-2H31.8a1,1,0,0,1,0,2Z"/><path class="cls-1a" d="M31.8,13H15.9a1,1,0,1,1,0-2H31.8a1,1,0,0,1,0,2Z"/></svg>')
         }else if(dataFromBigCard.$progress == '100'){

             dataToSmallCard.$progress.append('<svg class="done-icon" version="1.1" fill-opacity="0" width="49" height="49"><use xlink:href="#icon-complete-icon"></use> </svg>');
         } else {
            //call progress bar for pup-up element
            self.progresBarForPopUp();
         }

         function removeClass() {
            $cardObject.removeClass('red-gradient').removeClass('orange-gradient').removeClass('orange-gradient');
         }

         removeClass();

         // added gradient for card //
         if (dataFromBigCard.$progress < 25) {
            //add red gradient
             $cardObject.addClass('red-gradient');

         }
         else if (dataFromBigCard.$progress > 25 && dataFromBigCard.$progress < 50) {
            // add orenge gradient
            $cardObject.addClass('orange-gradient');
         }
         else {
            // add grenn- gradient
             $cardObject.addClass('green-gradient');
         }
      });

      $('.modal-close-object, .overlay').click(function () {
         $('.modal-object')
           .animate({opacity: 0, top: '50%'}, 200,
             function () {
                $(this).css('display', 'none');
                $('.overlay-object').fadeOut(400);
             }
           );
      });

   },

   progresBarForPopUp: function () {
      const idElement = $('#svg-circle-pop-up'),
        element = document.getElementById('svg-circle-pop-up');

      if (element) {

         let bar = new ProgressBar.Circle(element, {
            color: '#FFEA82',
            trailColor: '#eee',
            trailWidth: 1,
            duration: 1400,
            easing: 'easeInOut',
            strokeWidth: 6,
            text: {
               autoStyleContainer: false
            },
            step: function (state, circle) {

               let value = Math.round(circle.value() * 100);

               if (value === 0) {
                  circle.setText('');
               }
               else {
                  circle.setText(value + '%');
               }

               // added color for every category of percentages
               if (value < 25) {
                  circle.path.setAttribute('stroke', '#f8061d');
               }
               else if (value > 25 && value < 50) {
                  circle.path.setAttribute('stroke', '#ff6800');
               }
               else {
                  circle.path.setAttribute('stroke', '#50e3c2');
               }
            }
         });
         // here I put perenteges and  / it to 100
         bar.animate(+idElement.attr('value') / 100);//ourObjectsList[index].percentages / 100
         bar.text.style.color = '#010e40';
      }
   },

   shareClipboard: function () {

      const clipboard = new Clipboard('.share-сlipboard', {
         text: function () {
            if(document.location.pathname !== "/") {
               return document.location.href;
            } else {
                return `${document.location.host}/road-${ $('.open-card').attr('data-id')}`;
            }
         }
      });

      clipboard.on('success', function (e) {
         const notify = $('.notify-copy');
         notify.fadeIn(500);

         setTimeout(() => {
            notify.fadeOut(500, function () {
               $(this).css('display', 'none');
            });

         }, 2000);

         e.clearSelection();
      });


   },

   scrollToCard: function () {
      const picselToNeedScroll = 250;
      let $exist = $('.open-card').length;

      if ($exist) {
         $('.grid-item').find('a').on('click', function (e) {
            e.preventDefault();

            const id = $(this).data('id');

            $('html, body').animate({scrollTop: picselToNeedScroll}, 800, function () {
               window.location.hash = id;
            });
         });
      }

   }
};

export default OurObjectDetail;