const VacancyPage = {
   init: function () {
      this.Accordion();
   },

   /**
    * Vacancy accordion
    * */
   Accordion: function () {
      const $showMore = $('.vacancies-item__preview-show-more'),
         $previewMore = $('.vacancies-item__more'),
         $vacanciesWrapperPreview = $('.vacancies-item__preview');

      const toogleAccordion = function () {


         const $closerParent = $(this).closest('.vacancies-item'),
            $showMoreTitle = $(this),
            $previewMore = $closerParent.find('.vacancies-item__more'),
            $showmorearrow = $closerParent.find('.show-more-arrow'),
            $vacanciesWrapperPreviewList = $closerParent.find('.vacancies-item__preview-list'),
            $vacanciesWrapperPreviewInformation = $closerParent.find('.vacancies-item__preview-information'),
            $vacanciesWrapperPreview = $closerParent.find('.vacancies-item__preview');

         $showMoreTitle.toggleClass('show-more-effect');
         $vacanciesWrapperPreview.toggleClass('vacancies-wrapper-preview-effect');
         $showmorearrow.toggleClass('show-more-arrow-effect');
         $vacanciesWrapperPreviewList.toggleClass('vacancies-wrapper-preview-list-effect');
         $vacanciesWrapperPreviewInformation.toggleClass('vacancies-wrapper-preview-information-effect');
         $previewMore.stop(false, false).slideToggle(400);
      };

      if ($showMore.length) {
         $showMore.on('click', toogleAccordion);
      }
   }

};

export default VacancyPage;