
const News = {
   init: function () {
      this.NewsHover();
      this.toggleList();
      this.carouselInsideNews();
   },
    carouselInsideNews: function () {
       const articleCarousel = $('.article-carousel');

       function  checkPosition() {
           if (articleCarousel.find('.owl-prev').hasClass('disabled')){

               articleCarousel.find('.owl-stage').addClass('owl-stage-p-0')

               console.log('sss')
           } else {
               articleCarousel.find('.owl-stage').removeClass('owl-stage-p-0')
           }
       }


        articleCarousel.owlCarousel({
            loop: false,
            margin: 10,
            nav: true,
            stagePadding: 150,
            onInitialized: function () {
                checkPosition();
                console.log('here')
            },
            responsive: {
                0: {
                    items: 1,
                    stagePadding:0,
                },
                768: {
                    items:1
                },

            }
        }).on('changed.owl.carousel',function () {
            checkPosition()
         })



    },

   toggleList: function () {
       function setToggle(type) {
           const switchList = $('.switch-list-2'),
               switchTile = $('.switch-tile');

           // TODO НАФІГА Стільки КЛАСІВ ??????????????????????????????%№:@@
           if (type === 'list') {
               window.localStorage.setItem('showList', 'list');
               $newsWrap.removeClass('news-wrap-tile');
               $newsWrap.addClass('news-wrap-list');
               $newsLinkImgNone.removeClass('news-link-img-none-js');
               $newsDescriptionNone.removeClass('news-description-none-js');
               $newsLinkImg.removeClass('news-link-img-tile');
               $newsWrapItemImg.removeClass('news-wrap-item-img-block');
               $newsWrapItemTextTop.removeClass('news-wrap-item-text-top-tile');
               switchList.addClass('active');
               switchTile.removeClass('active');
           }else {
               window.localStorage.setItem('showList', 'tile');
               $newsWrap.removeClass('news-wrap-list');
               $newsWrap.addClass('news-wrap-tile');
               $newsLinkImg.addClass('news-link-img-tile');
               $newsWrapItemImg.addClass('news-wrap-item-img-block');
               $newsLinkImgNone.addClass('news-link-img-none-js');
               $newsDescriptionNone.addClass('news-description-none-js');
               $newsWrapItemTextTop.addClass('news-wrap-item-text-top-tile');
               switchTile.addClass('active');
               switchList.removeClass('active');
           }
       }

      // TODO OMG WHAT HAVE YOU DONE ????? need one toggle and other thing do by scss
      const $newsWrap = $('.news-wrap'),
         $newsLinkImg = $('.news-item__img-link-img'),
         $newsLinkImgNone = $('.news-item:nth-child(2) .news-item__img-link')
            .add('.news-item:nth-child(4) .news-item__img-link')
            .add('.news-item:nth-child(9) .news-item__img-link'),
         $newsDescriptionNone = $('.news-item:nth-child(1) .news-item__description')
            .add('.news-item:nth-child(3) .news-item__description')
            .add('.news-item:nth-child(5) .news-item__description')
            .add('.news-item:nth-child(6) .news-item__description')
            .add('.news-item:nth-child(7) .news-item__description')
            .add('.news-item:nth-child(8) .news-item__description'),
         $newsWrapItemImg = $('.news-item__img-link'),
         $newsWrapItemTextTop = $('.news-item__text-top'),
         $newsButtons = $('.switch').find('button');

       const showList = window.localStorage.getItem('showList') || 'list';

       // list or tile by localstorage //
       if (showList != 'null') {
           setToggle(showList);
       }

       // listener
      $newsButtons.on('click', function () {
         const type = $(this).data('type');
         $newsButtons.removeClass('active');
          setToggle(type)
      });

   },

   NewsHover: function () {

      var $newsWrapItemImg = $('.news-item__img-link'),
         $newslink = $('.news-item__title');

      $newsWrapItemImg.hover(function () {
         $(this).closest('.news-item').find('.news-item__title').toggleClass('news-link-effect');

      });

      $newslink.hover(function () {
         $(this).closest('.news-item').find('.news-item__img-link-img').toggleClass('news-wrap-item-img-effect');
      });
   }
};

export default News;




