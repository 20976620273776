import Settings from '../Settings';
import moment from "moment";
import 'moment/locale/uk';
import 'moment/locale/ru';
import 'moment/locale/en-gb';

const $slyList = $('#list1'),
    $frame = $('#hsscroll1'),
    $preloader = $('#preloader');

const photoReport = {

    // init
    init: function () {
        this.popupPhotoReport();
        this.ajaxRequestForPhotoSlider();
    },

    // run preloader
    ranPreloader: function () {
        $preloader.css('display', 'block');
    },

    // close preloader
    killPreloader: function () {
        $preloader.css('display', 'none');

    },

    // make HTTP request for get info for photogallery
    ajaxRequestForPhotoSlider: function () {
        const self = this;

        function callCarusel() {
            const that = this,
                albumFromClickEvent = $(this).attr('value') || window.location.hash.slice(1); //get value data from album

            // if (albumFromClickEvent != window.location.hash.slice(1)) {
                self.clickToPopUp();
            // }

            window.location.hash = albumFromClickEvent;

            function HttpRequest() {
                const domain = window.location.origin || 'http://pbs.bukovel.ua';

                // const el = window.location && window.location.origin ? window.location.origin : 'http://pbs.bukovel.ua'
                // XML_HTTP_REQUEST
                var url = '';

                // chose lang
                var lang = $('html').attr('lang');
                if (lang === 'en') {
                    url = `/en/api/album-${albumFromClickEvent}`
                } else if (lang === 'ru') {
                    url = `/ru/api/album-${albumFromClickEvent}`
                } else {
                    url = `/api/album-${albumFromClickEvent}`
                }

                $.get(url)
                    .done(function (data) {

                        let stringify = JSON.stringify(data.data),
                            jsonParse = JSON.parse(stringify),
                            $modalTopText = $('.modal-top-text'),
                            $modalTopDate = $('.modal-top-date');

                        // chose lang
                        if (lang === 'UK') {
                            moment.locale('uk');
                        } else if (lang === 'RU'){
                            moment.locale('ru');
                        } else{
                            moment.locale('en-gb');
                        }

                        let dateFormat = moment(jsonParse[0].create_date_album, 'YYYY-MM-DD').format('DD MMMM');
                        $modalTopText.text(jsonParse[0].title);
                        $modalTopDate.text(dateFormat);


                        $.each(jsonParse, function (index, item) {
                            $slyList.append(`<li data="${item.title}" class="ul-img-item">
                                <img src="${domain}/${item.url}" alt="${item.title}">
                                <div class="back-mask">
                                    <p class="description"> 
                                        ${ item.descr }
                                    </p>
                                </div>
                                               
                            </li>`);
                        });

                        /** more album**/
                        var moreAlbum = `<li class="more-album">
                            <h2>${data.label}</h2>
                            <div class="small-album__wrapper"></div>
                        </li>`

                        /** push more album only album exist **/
                        if (data.albums.length) {
                            $('#list1').append(moreAlbum);

                            data.albums.map(function (item) {
                                let date = moment(item.create_date_album, 'YYYY-MM-DD').format('DD MMMM');

                                $('.small-album__wrapper').append(`
                                 <a class="small-album__item" href="${domain}${item.url_album}" onclick="location.reload();">
                                    <img src="${domain}/${item.url}" alt="${item.title}">
                                    <span class="small-album">${ date }</span>
                                </a>
                            `)
                            })
                        }


                        self.caruselWithSlider();
                    }).fail(function () {
                    alert("We apologize for the confusion");
                });
            }

            HttpRequest();
        }

        const $length = $('#fotoreport-wrap').length,
            hash = window.location.hash.slice(1);

        if ($length && hash !== "") {
            callCarusel();
        }

        // This Hendler used for global carousel in bootom and for photoreport page //
        // Hendle click to alboum in fotoreport.html
        $('.fotoreport-item, .photo-carousel-item').on('click', callCarusel);
    },





    // init carousel
    caruselWithSlider: function () {
        var self = this;


        self.ranPreloader();
        /**
         * using media query for centered img in carusel list
         **/

        // Call Sly on frame
        var sly = new Sly($frame, {
            horizontal: 1,
            itemNav: 'basic',
            activateOn: 'click',
            mouseDragging: 1,
            scrollBar: $('.hsscrollbar'),
            touchDragging: 1,
            releaseSwing: 1,
            scrollBy: 1,
            startAt: 0,
            speed: 300,
            elasticBounds: 1,
            dragHandle: 1,
            dynamicHandle: 1,
            clickBar: 1,
            activeClass: 'active',
            prevPage: $('#prev-item'),
            nextPage: $('#next-item'),

        });

        // Its because destroy not work without it!
        setTimeout(()=> {
            sly.init();
            self.killPreloader();
        },500)

    },

    // open pop-up
    clickToPopUp: function () {
        $('.overlay').fadeIn(0, function () {
            $('body').addClass('background-blur');
                $('.modal-foto')
                    .css({'display': 'block'})
                    .animate({opacity: 1, top: '50%'}, 0);
            });
    },

    // lisener for close pop up, delete all item , and destroy carousel
    popupPhotoReport: function () {
        // remove all images from carousel
        function killSlideItems() {
            $frame.sly(false);
            $slyList.children().remove();
        }

        function shoutDownWindow() {
            killSlideItems();

            $('.modal-foto')
                .animate({opacity: 0, top: '50%'}, 200,
                    function () {
                        $('body').removeClass('background-blur');
                        $(this).css('display', 'none');
                        window.location.hash = ''; // kill hash in from url
                        $('.overlay').fadeOut(400);
                    }
                );
        }

        $('.modal-close, .overlay').click(shoutDownWindow);

        $(document).on('keydown',function (event){
            if(event.which === 27)
                shoutDownWindow()
        })
    },

};

export default photoReport;