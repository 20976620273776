import MapOfRoad from './MapOfRoad';

const Main = {
    init: function () {
        if (true) {
            this.newsCarusel();

        }
        // this.popUpForMain();
        this.openNavigationForRoad();
    },

    newsCarusel: function () {
        $('.news-carousel-wrap').owlCarousel({
            dots: true,
            nav: true,
            margin: 10,
            pagination: true,
            merge: true,
            lazyLoad: true,
            navClass: ['owl-prev-news', 'owl-next-news'],
            navText: true,
            loop: false,
            responsive: {
                0: {
                    items: 2,
                    margin: 255,
                },
                376: {
                    items: 1
                },
                480: {
                    items: 2
                },
                1000: {
                    items: 3
                },
                1200: {
                    items: 4
                },
                1400: {
                    items: 4
                }
            }
        });
    },





    openNavigationForRoad() {
        var $mobileOpenRoad = $('.mobile-open-road'),
            $mapBlockHeadClose = $('.map-block-head-close'),
            $closeMapWrap = $('.close-map-wrap'),
            $mapBlock = $('.map-block'),
            $maproads = $('.map-roads'),
            $blockMobile = $('.map-block-mobile');



        function openLegend() {
            $mapBlock.show()
            $maproads.addClass('nth-child-open');
        }

        // leave opened legend for desktop
        if ($(window).width() >= 768) {
            openLegend();
        } else {
            closeLegend();
        }


        function closeLegend() {
            $maproads.removeClass('nth-child-open')
            setTimeout(function (){
                $mapBlock.fadeOut('0.3s')
            },500)
        }
        $mobileOpenRoad.on('click', openLegend);


        $mapBlockHeadClose.on('click', function () {
            closeLegend()
        });

        $closeMapWrap.on('click', function () {
            closeLegend()
        });
    }
};




export default Main;