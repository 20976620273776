

const mapOfRoad = {
   init: function (){
      this.closeWindowHandle();
   },

   closeWindowHandle: function () {
      const $closeButton = $('.close-window-card');

      $closeButton.on('click', this.closeWind);
   },

   closeWind: function () {
      const $elementWhatWillBeClose = $('.one-open-card');
      $elementWhatWillBeClose.css({display:'none'});
   }
};

export default mapOfRoad;