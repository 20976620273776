const ourCompany =  {
   init: function () {
      this.scrollAnimation();
   },
   scrollAnimation: function () {

      /* scroll-animated-company */
      const $companyAnimated = $('.company-animated');

      if ($companyAnimated.length) {
         $(window).scroll(function () {
            $companyAnimated.each(function () {
               const positionTop = $(this).position().top;

               if ($(window).scrollTop() > (positionTop - 600)) {
                  $(this).addClass('showcompany');
               }
            });
         });
      }
   }
};

export  default ourCompany;