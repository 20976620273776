import ProgressBar from 'progressbar.js';

const ourObject = {

   init: function () {
      this.progressBar();
   },

   progressBar: function () {

      $.each($('.js-card__progress'), function (index, value) {

         const bar = new ProgressBar.Circle(value, {
            color: '#FFEA82',
            trailColor: '#979797',
            trailWidth: 1,
            duration: 1400,
            easing: 'easeInOut',
            strokeWidth: 6,
            text: {
               autoStyleContainer: false,
            },
            step: function (state, circle) {

               let value = Math.round(circle.value() * 100);

               if (value === 0) {
                  circle.setText('');
               }
               else {
                  circle.setText(+value + '%');
               }

               // added color for every category of percentages
               if (value < 25) {
                  circle.path.setAttribute('stroke', '#f8061d');
               }
               else if (value > 25 && value < 50) {
                  circle.path.setAttribute('stroke', '#ff6800');
               }
               else {
                  circle.path.setAttribute('stroke', '#50e3c2');
               }
            }
         });

         // here I put perenteges and  / it to 100
         bar.animate(+ $(this).attr('value') / 100);//ourObjectsList[index].percentages / 100
         bar.text.style.color = '#010E40';
         bar.text.style.fontSize = '16px';
         bar.text.style.fontWeight = 'bold';
      });
   }

};

export default ourObject;